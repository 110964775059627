body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "Righteous";
  src: local("Righteous"),
    url("./assets/fonts/Righteous-Regular.woff") format("woff");
}

@font-face {
  font-family: "Molot";
  src: local("Molot"),
    url("./assets/fonts/Molot.otf");
}

@font-face {
  font-family: "Ribuk";
  src: url("./assets/fonts/rubik-v21-latin-regular.ttf");
}

iframe {
  display: none;
}

video {
  object-fit: cover !important;
}

@keyframes breath2 {
  from {
    transform: skewX(-10deg) scaleX(1);
  }
  to {
    transform: skewX(-10deg) scaleX(0.95);
  }
}

#train {
  min-width: 300px;
  min-height: 60px;
  font-family: "Nunito", sans-serif;
  font-size: 22px;
  text-transform: uppercase;
  letter-spacing: 1.3px;
  font-weight: 700;
  color: #313133;
  background: "#00B9BA";
  border: none;
  border-radius: 1000px;
  box-shadow: 12px 12px 24px #00B9BA;
  transition: all 0.3s ease-in-out 0s;
  cursor: pointer;
  outline: none;
  position: relative;
  padding: 10px;
}

#train::before {
  content: "";
  border-radius: 1000px;
  min-width: calc(300px + 12px);
  min-height: calc(60px + 12px);
  border: 2px solid #00B9BA;
  box-shadow: 0 0 30px #00B9BA;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: all 0.3s ease-in-out 0s;
}

#train:hover,
#train:focus {
  color: #313133;
  transform: translateY(-6px);
}

#train:hover::before,
#train:focus::before {
  opacity: 1;
}
